import { Component, ElementRef, ViewChild } from '@angular/core';
import { ModalService } from '../../common/modal/modal.service';
import { CommonModule } from '@angular/common';
import { Observable, Subscription, timer } from 'rxjs';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import moment from 'moment';
import { OdometerDirective } from '@app/shared/lib/directive/odometer.directive';

@Component({
    selector: 'app-contest',
    standalone: true,
    imports: [
        CommonModule,
        OdometerDirective
    ],
    templateUrl: './contest.component.html',
    styles: ``
})
export class ContestComponent {
    private subs: Subscription[] = [];
    
    currentUser!:currentUserInfo | null;
    
    everySecond: Observable<number> = timer(0, 1000);

    hours!:string
    minutes!:string
    seconds!:string

    jackpot_money:number = 10000000
    tab:string = 'latest_bets'
    
    @ViewChild('flipdownDiv') flipdownDiv!: ElementRef;

    get modal() { return this.modalService }
    constructor(
        private modalService: ModalService,
        private authService: AuthService
    ) {

        setInterval(() => {
            const min = 10000;
            const max = 100000
            const value = Math.ceil(Math.random() * (max - min) + min);
            const plus = Math.ceil((Math.random() - 0.1) * 2) < 1 ? -1 : 1; // 10%
            this.jackpot_money += (value * plus)
        }, 3000)
    }

    ngOnInit() {
        this.subs.push(
            this.everySecond.subscribe((v) => {
                
                const remain = moment.tz('Asia/Seoul').hours(24).minutes(0).seconds(0)
                const timeLeft = remain.diff(moment(), 'seconds')

                const second = 1,
                minute = second * 60,
                hour = minute * 60,
                day = hour * 24;

                this.hours = Math.floor((timeLeft % (day)) / hour).toString();
                this.minutes = Math.floor((timeLeft % (hour)) / minute).toString();
                this.seconds = Math.floor((timeLeft % (minute)) / second).toString();
            })
        )

        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
            })
        )
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}
